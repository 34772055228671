<template>
  <div>
    <v-app>
      <v-main>
        <v-card
          width="100%"
          max-width="350px"
          style="
            position: absolute;
            top: calc(50% - 265px);
            right: 8%;
            z-index: 1000;
            margin: auto;
            border-radius: 15px !important;
            box-shadow: 0 0 25px #656565 !important;
          "
        >
          <img :src="logoPath" width="100%" />
          <v-card-title
            class="justify-center"
            style="font-size: 1.2rem; font-weight: 600"
          >
            LOGIN
          </v-card-title>
          <div class="mb-5 mt-1 pa-4">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="User Name"
                  placeholder=""
                  outlined
                  v-model="user_id"
                  hide-details
                  :autofocus="true"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Password"
                  placeholder=""
                  outlined
                  v-model="password"
                  type="password"
                  hide-details
                  :rules="passwordRules"
                  @keyup.enter="createlogin"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12">
                <vue-recaptcha
                  :loadRecaptchaScript="true"
                  :sitekey="capKey"
                  @verify="onVerify"
                  @expired="onExpired"
                  size="100"
                >
                </vue-recaptcha>
              </v-col> -->
            </v-row>
          </div>
          <v-btn
            color="primary"
            :loading="isloading"
            large
            style="width: 100%; border-radius: 1px 1px 10px 10px; height: 45px"
            @click="createlogin"
          >
            Login
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card>
        <video-background
          :src="bgVPath"
          :poster="bgIPath"
          :sources="[{ src: bgIPath, res: 900, autoplay: true }]"
          style="height: 100vh"
          overlay="linear-gradient(45deg,#2a4ae430,#fb949e6b)"
        >
        </video-background>
      </v-main>
      <v-snackbar v-model="snackbar" top :color="snackbarcolor">
        {{ snackbartext }}
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
//import axios from "axios";
import VideoBackground from "vue-responsive-video-background-player";

//import VueRecaptcha from "vue-recaptcha";

export default {
  layout: "login",
  // components: { "video-background": VideoBackground, VueRecaptcha },
  components: { "video-background": VideoBackground },
  props: { Eventurl: String },
  data() {
    return {
      isloading: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      loginStep: 1,
      user_id: "",
      password: "",
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 6) || "Password must have 6+ characters",
        (v) => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
        (v) => /(?=.*\d)/.test(v) || "Must have one number",
        (v) => /([@#$])/.test(v) || "Must have one special character [@#$]",
      ],
      capKey: "6LddAvIcAAAAAFtPO39lfaDlm2Dvb3m4HwI2dOcp",
      disablebutton: true,
      appData: {
        user_id: "",
        password: "",
        event_url: "",
        capResponse: "",
      },
      items: [],
      bgIPath: "",
      bgVPath: "",
    };
  },

  async created() {
    this.logoPath = this.$apiPath + "assets/" + this.Eventurl + "/logo.jpg";
    this.bgIPath = this.$apiPath + "assets/" + this.Eventurl + "/bg.jpg";
    this.bgVPath = this.$apiPath + "assets/" + this.Eventurl + "/bg.mp4";
  },

  // watch: {
  //   "appData.capResponse": function () {
  //     if (this.appData.capResponse != "") {
  //       this.disablebutton = false;
  //     }
  //   },
  // },

  methods: {
    // onVerify: function (response) {
    //   this.appData.capResponse = response;
    // },
    // onExpired: function () {
    //   this.appData.capResponse = "";
    //   this.disablebutton = true;
    // },

    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },
    createlogin: async function () {
      this.isloading = true;
      this.appData.user_id = this.user_id;
      this.appData.password = this.password;
      this.appData.event_url = this.Eventurl;
      if (this.appData.user_id == "" && this.appData.password) {
        this.showSnackbar("red", "Enter User Id and password !");
        this.isloading = false;
        return;
      }
      // if (this.appData.capResponse == "") {
      //   this.showSnackbar("red", "Please check capcha !");
      //   this.isloading = false;
      //   return;
      // }
      console.log(this.appData);

      await this.$axios
        .post("EventApp/EventAdminLogin", this.appData)
        .then(async (res) => {
         
       
          if (res.data.status == "Success") {
           
            await this.$store.commit("setEventUrl", this.Eventurl);

            await this.$store.commit("setEventInfo", res.data.result);

            if (
              res.data.result.u_type == "Admin" ||
              res.data.result.u_type == "CentralAdmin" || res.data.result.u_type=='SubAdmin'
            ) {
             //  console.log("HJJJJ");
              // console.log(res.data);
              window.location.replace("/admin/dashboard");
            } else if (res.data.result.u_type == "PrintAdmin") {
              // this.$router.push("/print/dashboard");
              window.location.replace("/print/dashboard");
            } else if (res.data.result.u_type == "DnaAdmin") {
 
              window.location.replace("/dna/dashboard");
            }
            else if (res.data.result.u_type == "Scanner") {
  this.showSnackbar("red", "Not authorised!");
            
            }
            
             else {
              window.location.replace("/media/dashboard");
            }
          } else {
            this.showSnackbar("red", "Invalid user name or password !");
          }
          this.isloading = false;
        })
        .catch()
        .finally();
    },
  },
};
</script>

<style>
.text-center {
  text-align: center;
}
.video-overlay {
  display: none !important;
}
</style>